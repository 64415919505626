import React from "react";
import { Grid, IconButton, useMediaQuery } from "@mui/material";
import ContainerWrapper from "./atoms/containerWrapper";
import TypographyWrapper from "./atoms/TypographyWrapper";
import CardWrapper from "./atoms/cardWrapper";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dashboardData from "./salesFieldExecutiveData";
import DataTable from "./atoms/tableWrapper";
import { useTheme } from "@emotion/react";
import Header from "../header/Header";


const HeroOfTheDayChallenge = () => {
  const navigate = useNavigate();

  const { keyMetrics, actionableInsight } = dashboardData.highMarginProductsDashboard;

    // Define columns and rows for DataTable
    const columns = [
      { label: "Metric", field: "metric" },
      { label: "Value", field: "value", align: "right" },
    ];
  
    const rows = [
      { metric: "High-Margin SKU:", value: keyMetrics.highMarginSku },
      { metric: "Top Outlet for Margins:", value: keyMetrics.topOutletForMargin },
      { metric: "Low Margin SKU:", value: keyMetrics.lowMarginSku },
    ];


    const theme = useTheme();

    const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  
    return (
      <ContainerWrapper>
        {isMediumDown && <Header isMediumDown={isMediumDown} page={"screensItem"} />}
  
        <Grid
          container
          spacing={3}
          sx={{
            marginTop: isMediumDown ? "10%" : "6%",
            padding: isMediumDown ? "3%" : "",
          }}
        >
        {/* Challenge Header */}
        <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
            High-Margin Products Dashboard
            </TypographyWrapper>
            <TypographyWrapper variant="body2" fontWeight="normal">
              {dashboardData.heroOfTheDayChallenge.challenge}
            </TypographyWrapper>
            <DataTable columns={columns} rows={rows} />
          </CardWrapper>
        </Grid>

        {/* Actionable Insight  */}
      <Grid item xs={12} md={12}>
        <CardWrapper>
          <TypographyWrapper variant="h6">
            Actionable Insight:
          </TypographyWrapper>
          <TypographyWrapper variant="body1" fontWeight="normal">
            {actionableInsight}
          </TypographyWrapper>
        </CardWrapper>
      </Grid>

        {/* Progress */}
        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Progress</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              Sold: {dashboardData.heroOfTheDayChallenge.progress.soldUnits}/
              {dashboardData.heroOfTheDayChallenge.progress.targetUnits}
              <span style={{ color: "red" }}>
                {" "}
                ({dashboardData.heroOfTheDayChallenge.progress.percentComplete})
              </span>
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* Current Rank */}
        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Current Rank
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="bold" color="red">
              #{dashboardData.heroOfTheDayChallenge.currentRank}
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* Close to Leader */}
        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Close to Leader
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.heroOfTheDayChallenge.closeToLeader}
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* Personal Achievement */}
        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Personal Achievement
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              Sold{" "}
              {
                dashboardData.heroOfTheDayChallenge.personalAchievement
                  .unitsSold
              }{" "}
              units in{" "}
              {dashboardData.heroOfTheDayChallenge.personalAchievement.time}
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* Badge Earned */}
        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Badge Earned
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.heroOfTheDayChallenge.badgeEarned}
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* Leaderboard */}
        {/* <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Leaderboard
            </TypographyWrapper>
            {dashboardData.heroOfTheDayChallenge.leaderboard.map(
              (entry, index) => (
                <TypographyWrapper
                  key={index}
                  variant="body1"
                  fontWeight={entry.name === "You" ? "bold" : "normal"}
                  color={entry.name === "You" ? "red" : "normal"}
                >
                  #{entry.rank} - {entry.name} ({entry.unitsSold} units)
                </TypographyWrapper>
              )
            )}
          </CardWrapper>
        </Grid> */}
      </Grid>
    </ContainerWrapper>
  );
};

export default HeroOfTheDayChallenge;
