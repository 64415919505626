import React from "react";
import { Grid, IconButton, useMediaQuery } from "@mui/material";
import ContainerWrapper from "./atoms/containerWrapper";
import TypographyWrapper from "./atoms/TypographyWrapper";
import CardWrapper from "./atoms/cardWrapper";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dashboardData from "./salesFieldExecutiveData";
import DataTable from "./atoms/tableWrapper";
import { useTheme } from "@emotion/react";
import Header from "../header/Header";

const SalesObstacleMap = () => {
  const navigate = useNavigate();

  const { keyMetrics, actionableInsight } = dashboardData.restockingAlertsDashboard;

    // Define columns and rows for DataTable
    const columns = [
      { label: "Metric", field: "metric" },
      { label: "Value", field: "value", align: "right" },
    ];
  
    const rows = [
      { metric: "Low Stock SKUs: 1.", value: keyMetrics.lowStockSkus.one },
      { metric: "Low Stock SKUs: 2.", value: keyMetrics.lowStockSkus.two },
      { metric: "Affected Outlets: 1.", value: keyMetrics.affectedOutlets.one },
      { metric: "Affected Outlets: 2.", value: keyMetrics.affectedOutlets.two },
    ];
    const theme = useTheme();

    const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  
    return (
      <ContainerWrapper>
        {isMediumDown && <Header isMediumDown={isMediumDown} page={"screensItem"} />}
  
        <Grid
          container
          spacing={3}
          sx={{
            marginTop: isMediumDown ? "10%" : "6%",
            padding: isMediumDown ? "3%" : "",
          }}
        >
        {/* Sales Obstacle Map Header */}
        <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
              Restocking Alerts Dashboard
            </TypographyWrapper>
            <TypographyWrapper variant="body2" fontWeight="normal">
              Identify key sales obstacles to plan mitigation strategies.
            </TypographyWrapper>
            <DataTable columns={columns} rows={rows} />
          </CardWrapper>
        </Grid>

        {/* Actionable Insight  */}
        <Grid item xs={12} md={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
              Actionable Insight:
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {actionableInsight}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Low Stock Outlets */}
        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Low Stock Outlets
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.salesObstacleMap.lowStockOutlets} outlets
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* Top Return Issues */}
        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Top Return Issues
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.salesObstacleMap.topReturnIssues}
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* Delayed Deliveries */}
        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Delayed Deliveries
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.salesObstacleMap.delayedDeliveries.count} delays
              (Avg. delay:{" "}
              {dashboardData.salesObstacleMap.delayedDeliveries.averageDelay})
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* Customer Complaints */}
        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Customer Complaints
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.salesObstacleMap.customerComplaints} complaints
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* Major Competitor Activity */}
        {/* <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Major Competitor Activity
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.salesObstacleMap.majorCompetitorActivity}
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}
      </Grid>
    </ContainerWrapper>
  );
};

export default SalesObstacleMap;
