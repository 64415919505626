import React from "react";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import AssistantIcon from "@mui/icons-material/Assistant";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const BottomNav = ({ value, onChange, isAuthenticated, isInputFocused }) => {
  const storedUser = sessionStorage.getItem("userDetails");
  const user = JSON.parse(storedUser);
  if (isInputFocused) return null; // Hide navigation when input is focused

  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={onChange}
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        background: "rgb(221 195 164)",
        zIndex: 1300,
        "& .MuiBottomNavigationAction-root": {
          color: "#757575",
        },
        "& .Mui-selected": {
          color: "#212121",
        },
        "& .MuiBottomNavigationAction-root.Mui-selected": {
          "& .MuiBottomNavigationAction-label": {
            color: "#212121",
          },
          "& .MuiSvgIcon-root": {
            color: "#212121",
          },
        },
      }}
    >
      <BottomNavigationAction label="Home" icon={<HomeIcon />} />
      <BottomNavigationAction label="Chat" icon={<AssistantIcon />} />
      <BottomNavigationAction label="Screens" icon={<TipsAndUpdatesIcon />} />
      <BottomNavigationAction
        label={isAuthenticated ? "Logout" : "Login"}
        icon={
          isAuthenticated ? (
            <div
              style={{
                width: 30,
                height: 30,
                borderRadius: "50%",
                backgroundColor: "#212121",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              {user?.name[0]?.toUpperCase()}
            </div>
          ) : (
            <AccountCircleIcon />
          )
        }
      />
    </BottomNavigation>
  );
};

export default BottomNav;
