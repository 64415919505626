import React from "react";
import JockeyLogo from "../assets/jockey_logo.png";

const Logo = ({ onClick }) => (
  <img
    fetchpriority="high"
    width={150}
    src={JockeyLogo}
    alt="Jockey Logo"
    sizes="(max-width: 600px) 100vw, 500px"
    style={{ cursor: onClick ? "pointer" : "default" }}
    onClick={onClick}
  />
);

export default Logo;
