const dashboardData = {
    skuPerformanceDashboard: {
      keyMetrics : {best: "Classic Vest (Men, Size L) - 150 Units Sold", fastest: "Active Shorts (Women) - +20% Growth", lowStock: "Boxer Briefs (Size XL) - Only 10 Units Left"},
      visuals : {},
      actionableInsight: "Push Active Shorts to outlets with low sales; restock Boxer Briefs in Bangalore South",
    },
    outletGrowthDashboard: {
      keyMetrics : {topOutlet: "Kumar's Mart (₹40,000 Revenue, +30% Growth)", atRiskOutlet: "Sharma Mart (₹15,000 Revenue, -15% Growth)", newHighValueOutlet: "Gupta Stores (₹25,000 Revenue in First Month)"},
      visuals : {},
      actionableInsight: "Plan a visit to Sharma Mart; prioritize thermals at Kumar's Mart due to seasonal demand",
    },
    seasonalTrendsDashboard: {
      keyMetrics : {topCategory: "40% Revenue Contribution", fastestGrowthCategory: "Lightweight Thermals (Women) - +25% Demand", regionalInsights: {southZone : "Lightweight Thermals dominate", northZone: "Heavyweight Thermals lead"}},
      visuals : {},
      actionableInsight: "Increase stock of lightweight thermals in South Zone and heavyweight thermals in North Zone",
    },
    highMarginProductsDashboard : {
      keyMetrics : {highMarginSku: "Sports Bra (Women, Size M) - 30% Margin", topOutletForMargin: "Vijay General Store - ₹25,000 from high-margin SKUs", lowMarginSku: "Jogger Pants (Kids) - 10% Margin"},
      visuals : {},
      actionableInsight: "Focus margin-driven SKUs like Sports Bras in high-potential outlets",
    },
    competitorTrackingDashboard : {
      keyMetrics : {competitorPromo: "Lux Cozi - 15% Discount on Innerwear at Gupta Stores", competitorStocking: "Van Heusen SKUs increased in Kumar's Mart (+20%)"},
      visuals : {},
      actionableInsight: "Plan counter-promotions for high-competition outlets; offer bundle discounts for thermals",
    },
    restockingAlertsDashboard : {
      keyMetrics : {lowStockSkus: {one: "Boxer Briefs (Size XL): 10 Units Left", two: "Sports Shorts (Men, Size L): 5 Units Left"}, affectedOutlets: {one: "Vijay General Store", two:"Gupta Stores"}},
      visuals : {},
      actionableInsight: "Restock Boxer Briefs and Sports Shorts in Bangalore South immediately",
    },
    productAssortmentDashboard : {
      keyMetrics : {outletsStockingFullRange: "60%", revenueFromFullRange: "₹300,000 (70% of total)", outletsMissingKeySkus: "10"},
      visuals : {},
      actionableInsight: "Focus on filling assortment gaps in high-revenue outlets like Kumar's Mart",
    },
    salesFunnelDashboard : {
      keyMetrics : {outletsInPipeline: "50 (30 Engaged, 10 Converted)", conversionRate: "20%", averageOrderValue: "₹8,000"},
      visuals : {},
      actionableInsight: "Focus on converting engaged outlets; prioritize follow-ups with top prospects",
    },
    lostSalesDashboard : {
      keyMetrics : {lostRevenue: "₹50,000", topReasons: {stockOut: "40%", delayedDeliveries: "30%"}, affectedSkus: "Boxer Briefs, Active Shorts"},
      visuals : {},
      actionableInsight: "Resolve stock-outs for high-demand SKUs; work on delivery optimization",
    },
    customerFeedbackDashboard : {
      keyMetrics : {satisfactionScore: "4.5/5", posFeedback: "Great comfort and fit (Classic Vest)", negFeedback: "Poor packaging quality (Thermals)"},
      visuals : {},
      actionableInsight: "Address packaging concerns for thermals; highlight positive feedback for Classic Vests in promotions",
    },
    //old data (UNIBIC screens data below)
    dailySalesPulse: {
      todaysSales: { value: "₹150,000", target: "₹180,000", achieved: "83%" },
      topProductOfDay: { name: "Butter Cookies", unitsSold: 320 },
      totalRevenueWeekly: "₹900,000",
      leaderboard: { rank: 3, totalRanks: 15, nextMilestone: "₹170,000 to reach #2" },
      weeklyTargetCompletion: "70%",
      averageOrderValue: "₹3,000",
      conversionRate: "35%",
      returnRateToday: "2%"
    },
    topOutletOpportunities: [
      {
        outletName: "Mishra’s General Store",
        salesPotential: "High",
        lastPurchase: "3 Days Ago",
        topProductsToPitch: ["Almond Biscotti", "Oat Cookies"],
        estimatedRevenue: "₹25,000"
      },
      {
        outletName: "Sharma Mart",
        salesPotential: "Medium",
        productsToPitch: ["Sugar-Free Cookies"],
        estimatedRevenue: "₹18,000"
      }
    ],
    newOutletRadar: {
      outletName: "SuperMaya Market",
      revenueForecast: "₹15,000 Monthly",
      introProducts: ["Butter Cookies", "Biscotti Range"],
      competitorStock: "GoodDay Biscuits",
      distance: "1.2 km",
      firstScheduledVisit: "Tomorrow, 10 AM"
    },
    heroOfTheDayChallenge: {
      challenge: "Sell 50 Units of Choco Almond Cookies Today",
      progress: { soldUnits: 40, targetUnits: 50, percentComplete: "80%" },
      currentRank: 2,
      closeToLeader: "45 Units",
      personalAchievement: { unitsSold: 30, time: "1 Hour" },
      badgeEarned: "Cookie Champ",
      leaderboard: [
        { rank: 1, name: "Arjun", unitsSold: 45 },
        { rank: 2, name: "You", unitsSold: 40 }
      ]
    },
    productPushPriority: {
      topProduct: "Almond Biscotti",
      stockLevel: "High",
      currentSalesRankRegion: 2,
      unitsSoldToday: { value: 120, target: 200 },
      suggestedOutlets: ["Mishra’s General Store", "SuperMaya Market"],
      promotion: "10% Off for the Week"
    },
    salesObstacleMap: {
      lowStockOutlets: 4,
      topReturnIssues: "Packaging concerns on Chocolate Cookies",
      delayedDeliveries: { count: 3, averageDelay: "2 days" },
      customerComplaints: 8,
      majorCompetitorActivity: "Oreo promo at 3 outlets"
    },
    instantWins: {
      recentHighValueSale: { value: "₹30,000", outlet: "Sharma Mart" },
      topProductSold: "Choco Almond Cookies",
      customerFeedback: "Great taste and quality!",
      totalWinsToday: 4,
      newOutletAdded: "Delight Grocery"
    },
    nextBestAction: {
      suggestedOutlet: { name: "SuperMaya Market", distance: "1.2 km" },
      predictedSalesValue: "₹10,000",
      lastPurchaseDate: "10 Days Ago",
      orderHistory: { count: 5, averageValue: "₹8,000" }
    },
    competitorPulse: {
      competitorPromoObserved: "15% Off on GoodDay Cookies at Sharma Mart",
      topCompetitorProductsNearby: ["Oreo", "Sunfeast Digestive"],
      newCompetitorStockAtOutlets: { product: "GoodDay", location: "SuperMaya Market" },
      estimatedImpact: "Reduced sales on Digestive Line by 10%"
    },
    customerFeedbackScorecard: {
      satisfactionScore: "4.5/5",
      topPositiveFeedback: "Almond Biscotti is a hit!",
      topNegativeFeedback: "Packaging could be sturdier",
      repeatCustomerRate: "65%",
      resolvedIssues: { count: 10, total: 12 }
    }
  };
  export default dashboardData;
