import React from "react";
import { Grid, IconButton, useMediaQuery } from "@mui/material";
import ContainerWrapper from "./atoms/containerWrapper";
import TypographyWrapper from "./atoms/TypographyWrapper";
import CardWrapper from "./atoms/cardWrapper";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dashboardData from "./salesFieldExecutiveData";
import DataTable from "./atoms/tableWrapper";
import { useTheme } from "@emotion/react";
import Header from "../header/Header";

const NewOutletRadar = () => {
  const navigate = useNavigate();

  const {
    outletName,
    revenueForecast,
    introProducts,
    competitorStock,
    distance,
    firstScheduledVisit,
  } = dashboardData.newOutletRadar;

  const { keyMetrics, actionableInsight } =
    dashboardData.seasonalTrendsDashboard;

    // Define columns and rows for DataTable
    const columns = [
      { label: "Metric", field: "metric" },
      { label: "Value", field: "value", align: "right" },
    ];
  
    const rows = [
      { metric: "Top Category:", value: keyMetrics.topCategory },
      { metric: "Fastest Growth:", value: keyMetrics.fastestGrowthCategory },
      { metric: "Region Insights(South Zone):", value: keyMetrics.regionalInsights.southZone },
      { metric: "Region Insights(North Zone):", value: keyMetrics.regionalInsights.northZone },
    ];
    const theme = useTheme();

    const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  
    return (
      <ContainerWrapper>
        {isMediumDown && <Header isMediumDown={isMediumDown} page={"screensItem"} />}
  
        <Grid
          container
          spacing={3}
          sx={{
            marginTop: isMediumDown ? "10%" : "6%",
            padding: isMediumDown ? "3%" : "",
          }}
        >
        {/* Header and Description */}
        <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
              Seasonal Trends Dashboard
            </TypographyWrapper>
            <TypographyWrapper variant="body2" fontWeight="normal">
              It helps you identify and{" "}
              <span style={{ backgroundColor: "yellow" }}>
                track new and fast-growing retail outlets
              </span>{" "}
              to optimize sales and distribution strategies.
            </TypographyWrapper>
            <DataTable columns={columns} rows={rows} />
          </CardWrapper>
        </Grid>

        {/* Actionable Insight  */}
        <Grid item xs={12} md={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
              Actionable Insight:
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {actionableInsight}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Outlet Details */}
        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Outlet Name
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {outletName}
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Revenue Forecast
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {revenueForecast}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Intro Products
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {introProducts.join(", ")}
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Competitor Stock
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {competitorStock}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Distance</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {distance}
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              First Scheduled Visit
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {firstScheduledVisit}
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}
      </Grid>
    </ContainerWrapper>
  );
};

export default NewOutletRadar;
