import React from "react";
import { Grid, IconButton, useMediaQuery } from "@mui/material";
import ContainerWrapper from "./atoms/containerWrapper";
import TypographyWrapper from "./atoms/TypographyWrapper";
import CardWrapper from "./atoms/cardWrapper";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dashboardData from "./salesFieldExecutiveData";
import DataTable from "./atoms/tableWrapper";
import { useTheme } from "@emotion/react";
import Header from "../header/Header";

const CustomerFeedbackScorecard = () => {
  const navigate = useNavigate();

  const { keyMetrics, actionableInsight } = dashboardData.customerFeedbackDashboard;

    // Define columns and rows for DataTable
    const columns = [
      { label: "Metric", field: "metric" },
      { label: "Value", field: "value", align: "right" },
    ];
  
    const rows = [
      { metric: "Satisfaction Score:", value: keyMetrics.satisfactionScore },
      { metric: "Positive Feedback:", value: keyMetrics.posFeedback },
      { metric: "Negative Feedback:", value: keyMetrics.negFeedback },
    ];

    const theme = useTheme();

    const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  
    return (
      <ContainerWrapper>
        {isMediumDown && <Header isMediumDown={isMediumDown} page={"screensItem"} />}
  
        <Grid
          container
          spacing={3}
          sx={{
            marginTop: isMediumDown ? "10%" : "6%",
            padding: isMediumDown ? "3%" : "",
          }}
        >
        {/* Customer Feedback Scorecard Header */}
        <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
              Customer Feedback Scorecard
            </TypographyWrapper>
            <TypographyWrapper variant="body2" fontWeight="normal">
              A summary of customer feedback and satisfaction metrics.
            </TypographyWrapper>
            <DataTable columns={columns} rows={rows} />
          </CardWrapper>
        </Grid>

        {/* Actionable Insight  */}
        <Grid item xs={12} md={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
              Actionable Insight:
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {actionableInsight}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Satisfaction Score */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Satisfaction Score</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.customerFeedbackScorecard.satisfactionScore}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Top Positive Feedback */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Top Positive Feedback</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {keyMetrics.posFeedback}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Top Negative Feedback */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Top Negative Feedback</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.customerFeedbackScorecard.topNegativeFeedback}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Repeat Customer Rate */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Repeat Customer Rate</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.customerFeedbackScorecard.repeatCustomerRate}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Resolved Issues */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Resolved Issues</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.customerFeedbackScorecard.resolvedIssues.count} out of{" "}
              {dashboardData.customerFeedbackScorecard.resolvedIssues.total} issues resolved
            </TypographyWrapper>
          </CardWrapper>
        </Grid>
      </Grid>
    </ContainerWrapper>
  );
};

export default CustomerFeedbackScorecard;
