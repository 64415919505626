const sampleQuestions = [
        "Does style code #AG19 run true to size?",
        "Is style code #RX71 available in size XL?",
        "What’s the fit for style code #MV24?",
        "Is style code #AG54 available in small?",
        "Can I get style code #IC30 in a slim fit?",
        "Is style code #8035 available in plus sizes?",
        "Does style code #AP27 have a relaxed fit?",
        "Is style code #AW89 available in oversized?",
        "What are the size measurements for style code #T445?",
        "What’s the newest style code for men's T-shirts?",
        "Show me the latest products for women’s activewear.",
        "What’s the most recent style code in the boxer collection?",
        "What new arrivals do you have in men's innerwear?",
        "What’s the latest style code in the casualwear range?",
        "Are there any new arrivals for women's leggings?",
        "Show me the newest style codes for sports bras.",
        "What are the latest designs in men's loungewear?",
        "What’s the newest product in the Jockey premium collection?",
        "Any new arrivals in women's underwear?",
        "How should I wash style code #AG72?",
        "What are the care instructions for style code #AM71?",
        "Can I machine wash style code #CB01?",
        "Does style code #US59 need to be air dried?",
        "Can I tumble dry style code #MV55?",
        "Does style code #UM36 need to be hand washed?",
        "Is style code #FP03 dry-clean only?",
        "Can I iron style code #NY16?",
        "What’s the washing temperature for style code #MK03?",
        "Is style code #SP17 shrink-resistant?",
        "Can you recommend a good boxer for men?",
        "What’s a good product for women's sports bras?",
        "Suggest a comfortable everyday T-shirt for men.",
        "What’s a good style code for high-impact activities?",
        "Can you recommend a popular product in loungewear?",
        "What’s the best product for women’s leggings?",
        "Which style code is best for running?",
        "What’s a good recommendation for a gift in men’s underwear?",
        "What’s the most durable style code for men’s socks?",
        "What’s the best Jockey product for hot weather?",
        "Is there a warranty for style code #AM75?",
        "What’s the return policy for style code #3913?",
        "Can I return style code #SG11 if it doesn’t fit?",
        "What’s the warranty period for style code #RX12?",
        "Can I exchange style code #7480 for another size?",
        "Is style code #RX57 eligible for a refund?",
        "What’s the return window for style code #MW31?",
        "Do I need a receipt to return style code #AW22?",
        "Is style code #7396 covered under warranty?",
        "What’s the process for returning style code #RX25?"
];
module.exports = sampleQuestions;
