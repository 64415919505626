import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const NavLink = ({ to, label }) => {
  const [isHovered, setIsHovered] = useState(false);
  const location = useLocation();

  const isActive = location.pathname === to;

  return (
    <Link
      to={to}
      style={{
        textDecoration: isActive ? "underline" : "none",
        color: isHovered || isActive ? "#263238" : "#555555",
        fontSize: "16px",
        fontWeight: "bold",
        transform: isHovered ? "translateY(-3px)" : "translateY(0)",
        transition: "all 0.3s ease-in-out",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {label}
    </Link>
  );
};

export default NavLink;
