import React from "react";
import { Grid, IconButton, useMediaQuery } from "@mui/material";
import ContainerWrapper from "./atoms/containerWrapper";
import TypographyWrapper from "./atoms/TypographyWrapper";
import CardWrapper from "./atoms/cardWrapper";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dashboardData from "./salesFieldExecutiveData";
import DataTable from "./atoms/tableWrapper";
import { useTheme } from "@emotion/react";
import Header from "../header/Header";

const InstantWins = () => {
  const navigate = useNavigate();

  const { keyMetrics, actionableInsight } = dashboardData.productAssortmentDashboard;

    // Define columns and rows for DataTable
    const columns = [
      { label: "Metric", field: "metric" },
      { label: "Value", field: "value", align: "right" },
    ];
  
    const rows = [
      { metric: "Outlets Stocking Full Range:", value: keyMetrics.outletsStockingFullRange },
      { metric: "Revenue Contribution from Full-Range Outlets:", value: keyMetrics.revenueFromFullRange },
      { metric: "Outlets Missing Key SKUs:", value: keyMetrics.outletsMissingKeySkus },
    ];
    const theme = useTheme();

    const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  
    return (
      <ContainerWrapper>
        {isMediumDown && <Header isMediumDown={isMediumDown} page={"screensItem"} />}
  
        <Grid
          container
          spacing={3}
          sx={{
            marginTop: isMediumDown ? "10%" : "6%",
            padding: isMediumDown ? "3%" : "",
          }}
        >
        {/* Instant Wins Header */}
        <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">Product Assortment Dashboard</TypographyWrapper>
            <TypographyWrapper variant="body2" fontWeight="normal">
              Celebrate the immediate sales successes and customer feedback.
            </TypographyWrapper>
            <DataTable columns={columns} rows={rows} />
          </CardWrapper>
        </Grid>

        {/* Actionable Insight  */}
        <Grid item xs={12} md={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
              Actionable Insight:
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {actionableInsight}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Recent High-Value Sale */}
        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Recent High-Value Sale
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.instantWins.recentHighValueSale.value} at{" "}
              {dashboardData.instantWins.recentHighValueSale.outlet}
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* Top Product Sold */}
        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Top Product Sold
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.instantWins.topProductSold}
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* Customer Feedback */}
        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Customer Feedback
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              "{dashboardData.instantWins.customerFeedback}"
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* Total Wins Today */}
        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Total Wins Today
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.instantWins.totalWinsToday} wins
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* New Outlet Added */}
        {/* <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              New Outlet Added
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.instantWins.newOutletAdded}
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}
      </Grid>
    </ContainerWrapper>
  );
};

export default InstantWins;
