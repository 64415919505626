const { useTheme } = require("@emotion/react");
const { useMediaQuery } = require("@mui/material");

const Suggestions = ({ handleSend }) => {
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  const isLandscape = useMediaQuery("(orientation: landscape)");

  const sampleQuestions = require("./sampleQuestions");

  const getRandomQuestion = () => {
    let remainingQuestions;
    remainingQuestions = [...sampleQuestions];

    const randomIndex = Math.floor(Math.random() * remainingQuestions?.length);
    const question = remainingQuestions[randomIndex];
    remainingQuestions.splice(randomIndex, 1);
    return question;
  };

  const handleRandomQuestion = () => {
    const randomQuestion = getRandomQuestion();
    handleSend(randomQuestion);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: isMediumDown ? (isLandscape ? "" : "4%") : "5%",
        alignItems: "center",
        marginLeft: isMediumDown ? "" : "10%",
      }}
    >
      <div
        style={{
          border: "0.5px solid #212121",
          borderRadius: "5px",
          padding: "10px",
          color: "#212121",
          fontSize: isMediumDown ? "12px" : "16px",
          cursor: "pointer",
          fontWeight: 500,
          backgroundColor: "rgb(255 255 255 / 54%)",
        }}
        onClick={handleRandomQuestion}
      >
        Check out our sample questions!!{" "}
      </div>
    </div>
  );
};

export default Suggestions;
