import React from "react";
import { Grid, IconButton, useMediaQuery } from "@mui/material";
import ContainerWrapper from "./atoms/containerWrapper";
import TypographyWrapper from "./atoms/TypographyWrapper";
import CardWrapper from "./atoms/cardWrapper";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dashboardData from "./salesFieldExecutiveData";
import DataTable from "./atoms/tableWrapper";
import { useTheme } from "@emotion/react";
import Header from "../header/Header";

const ProductPushPriority = () => {
  const navigate = useNavigate();

  const { keyMetrics, actionableInsight } =
    dashboardData.competitorTrackingDashboard;

    // Define columns and rows for DataTable
    const columns = [
      { label: "Metric", field: "metric" },
      { label: "Value", field: "value", align: "right" },
    ];
  
    const rows = [
      { metric: "Competitor Promo:", value: keyMetrics.competitorPromo },
      { metric: "Competitor Stocking:", value: keyMetrics.competitorStocking },
    ];

    const theme = useTheme();

    const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  
    return (
      <ContainerWrapper>
        {isMediumDown && <Header isMediumDown={isMediumDown} page={"screensItem"} />}
  
        <Grid
          container
          spacing={3}
          sx={{
            marginTop: isMediumDown ? "10%" : "6%",
            padding: isMediumDown ? "3%" : "",
          }}
        >
        {/* Product Header */}
        <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
              Competitor Tracking Dashboard
            </TypographyWrapper>
            <TypographyWrapper variant="body2" fontWeight="normal">
              Promote the product with high priority to boost sales in the
              region.
            </TypographyWrapper>
            <DataTable columns={columns} rows={rows} />
          </CardWrapper>
        </Grid>

        {/* Actionable Insight  */}
        <Grid item xs={12} md={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
              Actionable Insight:
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {actionableInsight}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Top Product */}
        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Top Product
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.productPushPriority.topProduct}
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* Stock Level */}
        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Stock Level
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.productPushPriority.stockLevel}
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* Current Sales Rank */}
        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Current Sales Rank
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              #{dashboardData.productPushPriority.currentSalesRankRegion}
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* Units Sold Today */}
        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Units Sold Today
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.productPushPriority.unitsSoldToday.value}/
              {dashboardData.productPushPriority.unitsSoldToday.target}
              <span style={{ color: "red" }}>
                {" "}
                (
                {(
                  (dashboardData.productPushPriority.unitsSoldToday.value /
                    dashboardData.productPushPriority.unitsSoldToday.target) *
                  100
                ).toFixed(2)}
                %)
              </span>
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* Suggested Outlets */}
        {/* <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Suggested Outlets
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.productPushPriority.suggestedOutlets.join(", ")}
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* Promotion */}
        {/* <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Promotion</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.productPushPriority.promotion}
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}
      </Grid>
    </ContainerWrapper>
  );
};

export default ProductPushPriority;
