import React from "react";
import { Grid, IconButton, useMediaQuery } from "@mui/material";
import ContainerWrapper from "./atoms/containerWrapper";
import TypographyWrapper from "./atoms/TypographyWrapper";
import CardWrapper from "./atoms/cardWrapper";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dashboardData from "./salesFieldExecutiveData";
import DataTable from "./atoms/tableWrapper";
import { useTheme } from "@emotion/react";
import Header from "../header/Header";

const NextBestAction = () => {
  const navigate = useNavigate();

  const { keyMetrics, actionableInsight } = dashboardData.salesFunnelDashboard;

    // Define columns and rows for DataTable
    const columns = [
      { label: "Metric", field: "metric" },
      { label: "Value", field: "value", align: "right" },
    ];
  
    const rows = [
      { metric: "Outlets in Pipeline:", value: keyMetrics.outletsInPipeline },
      { metric: "Conversion Rate:", value: keyMetrics.conversionRate },
      { metric: "Average Order Value (AOV):", value: keyMetrics.averageOrderValue },
    ];
    const theme = useTheme();

    const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  
    return (
      <ContainerWrapper>
        {isMediumDown && <Header isMediumDown={isMediumDown} page={"screensItem"} />}
  
        <Grid
          container
          spacing={3}
          sx={{
            marginTop: isMediumDown ? "10%" : "6%",
            padding: isMediumDown ? "3%" : "",
          }}
        >
        {/* Next Best Action Header */}
        <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
            Sales Funnel Dashboard
            </TypographyWrapper>
            <TypographyWrapper variant="body2" fontWeight="normal">
              Here’s the suggested action to maximize sales.
            </TypographyWrapper>
            <DataTable columns={columns} rows={rows} />
          </CardWrapper>
        </Grid>

        {/* Actionable Insight  */}
        <Grid item xs={12} md={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
              Actionable Insight:
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {actionableInsight}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Suggested Outlet */}
        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Suggested Outlet</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.nextBestAction.suggestedOutlet.name}{" "}
              ({dashboardData.nextBestAction.suggestedOutlet.distance} away)
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* Predicted Sales Value */}
        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Predicted Sales Value</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.nextBestAction.predictedSalesValue}
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* Last Purchase Date */}
        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Last Purchase Date</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.nextBestAction.lastPurchaseDate}
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* Order History */}
        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Order History</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.nextBestAction.orderHistory.count} orders with an average value of{" "}
              {dashboardData.nextBestAction.orderHistory.averageValue}
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}
      </Grid>
    </ContainerWrapper>
  );
};

export default NextBestAction;
