import React from "react";
import { Grid, IconButton, useMediaQuery } from "@mui/material";
import ContainerWrapper from "./atoms/containerWrapper";
import TypographyWrapper from "./atoms/TypographyWrapper";
import CardWrapper from "./atoms/cardWrapper";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dashboardData from "./salesFieldExecutiveData";
import DataTable from "./atoms/tableWrapper";
import { useTheme } from "@emotion/react";
import Header from "../header/Header";

const CompetitorPulse = () => {
  const navigate = useNavigate();

  const { keyMetrics, actionableInsight } = dashboardData.lostSalesDashboard;

    // Define columns and rows for DataTable
    const columns = [
      { label: "Metric", field: "metric" },
      { label: "Value", field: "value", align: "right" },
    ];
  
    const rows = [
      { metric: "Lost Revenue (Last Month):", value: keyMetrics.lostRevenue },
      { metric: "Top Reasons: 1.", value: keyMetrics.topReasons.stockOut },
      { metric: "Top Reasons: 2.", value: keyMetrics.topReasons.delayedDeliveries },
      { metric: "Affected SKUs:", value: keyMetrics.affectedSkus },
    ];
    const theme = useTheme();

    const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  
    return (
      <ContainerWrapper>
        {isMediumDown && <Header isMediumDown={isMediumDown} page={"screensItem"} />}
  
        <Grid
          container
          spacing={3}
          sx={{
            marginTop: isMediumDown ? "10%" : "6%",
            padding: isMediumDown ? "3%" : "",
          }}
        >
        <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
            Lost Sales Dashboard
            </TypographyWrapper>
            <TypographyWrapper variant="body2" fontWeight="normal">
              Key competitor activities and their impact.
            </TypographyWrapper>
            <DataTable columns={columns} rows={rows} />
          </CardWrapper>
        </Grid>

        {/* Actionable Insight  */}
        <Grid item xs={12} md={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
              Actionable Insight:
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {actionableInsight}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Competitor Promo Observed */}
        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Competitor Promo Observed</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.competitorPulse.competitorPromoObserved}
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* Top Competitor Products Nearby */}
        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Top Competitor Products Nearby</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.competitorPulse.topCompetitorProductsNearby.join(", ")}
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* New Competitor Stock at Outlets */}
        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">New Competitor Stock at Outlets</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.competitorPulse.newCompetitorStockAtOutlets.product} at{" "}
              {dashboardData.competitorPulse.newCompetitorStockAtOutlets.location}
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}

        {/* Estimated Impact */}
        {/* <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Estimated Impact</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.competitorPulse.estimatedImpact}
            </TypographyWrapper>
          </CardWrapper>
        </Grid> */}
      </Grid>
    </ContainerWrapper>
  );
};

export default CompetitorPulse;
