import React from "react";
import { Card } from "@mui/material";

const CardWrapper = ({ children, sx }) => {
  return (
    <Card
      sx={{
        padding: 3,
        borderRadius: 5,
        boxShadow: 0,
        backgroundColor:"#ffffff9e",
        ...sx, 
      }}
    >
      {children}
    </Card>
  );
};

export default CardWrapper;
