import React from "react";
import { Button } from "@mui/material";

const ActionButton = ({ label, onClick }) => (
  <Button
    onClick={onClick}
    sx={{
      color: "#263238",
      fontSize: "16px",
      fontWeight: "bold",
    }}
  >
    {label}
  </Button>
);

export default ActionButton;
