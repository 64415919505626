import React from "react";
import { Grid, IconButton, Typography, useMediaQuery } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import CardWrapper from "./atoms/cardWrapper";
import TypographyWrapper from "./atoms/TypographyWrapper";
import dashboardData from "./salesFieldExecutiveData";
import ContainerWrapper from "./atoms/containerWrapper";
import DataTable from "./atoms/tableWrapper";
import { useTheme } from "@emotion/react";
import Header from "../header/Header";

const TopOutletOpportunities = () => {
  const navigate = useNavigate();
  // const columns = [
  //   { label: "Outlet Name", field: "outletName" },
  //   { label: "Sales Potential", field: "salesPotential" },
  //   { label: "Last Purchase", field: "lastPurchase" },
  //   {
  //     label: "Top Products to Pitch",
  //     field: "topProductsToPitch",
  //     render: (row) =>
  //       row.topProductsToPitch
  //         ? row.topProductsToPitch.join(", ")
  //         : "No Products Available",
  //   },
  //   { label: "Estimated Revenue", field: "estimatedRevenue", align: "right" },
  // ];

  // // Row data
  // const rows = dashboardData.topOutletOpportunities;

  const { keyMetrics, actionableInsight } = dashboardData.outletGrowthDashboard;

  // Define columns and rows for DataTable
  const columns = [
    { label: "Metric", field: "metric" },
    { label: "Value", field: "value", align: "right" },
  ];

  const rows = [
    // { metric: "Today’s Sales", value: todaysSales.value },
    // { metric: "Sales Target", value: todaysSales.target },
    // { metric: "Percentage Achieved", value: todaysSales.achieved },
    { metric: "Top Outlet:", value: keyMetrics.topOutlet },
    { metric: "At-Risk Outlet:", value: keyMetrics.atRiskOutlet },
    { metric: "New High-Value Outlet:", value: keyMetrics.newHighValueOutlet },
  ];
  const theme = useTheme();

  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <ContainerWrapper>
      {isMediumDown && <Header isMediumDown={isMediumDown} page={"screensItem"} />}

      <Grid
          container
          spacing={3}
          sx={{
            marginTop: isMediumDown ? "10%" : "6%",
            padding: isMediumDown ? "3%" : "",
          }}
        >
                  <Grid item xs={12}>

      <CardWrapper>
        <TypographyWrapper variant="h6">
          Outlet Growth Dashboard
        </TypographyWrapper>
        <Typography variant="body2">
          Highlighting stores with high sales potential and recommended
          products.
        </Typography>

        <DataTable columns={columns} rows={rows} />
      </CardWrapper>
      </Grid>

      {/* Actionable Insight  */}
      <Grid item xs={12} md={12}>
        <CardWrapper>
          <TypographyWrapper variant="h6">
            Actionable Insight:
          </TypographyWrapper>
          <TypographyWrapper variant="body1" fontWeight="normal">
            {actionableInsight}
          </TypographyWrapper>
        </CardWrapper>
      </Grid>
      </Grid>
    </ContainerWrapper>
  );
};

export default TopOutletOpportunities;
