import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import CategorySidebar from "./SalesSidebar";
import Header from "./header/Header";

const ItemPage = () => {
  const location = useLocation();
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const isLandscape = useMediaQuery("(orientation: landscape)");

  const { question, data, categories } = location.state || {};

  const [page, setPage] = useState(0);

  const [itemsPerPage, setItemsPerPage] = useState(isMediumDown ? 6 : 10);
  const startIndex = page * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData = data.slice(startIndex, endIndex);
  useEffect(() => {
    const handleResize = () => {
      const availableHeight = window.innerHeight * 0.8;
      const newItemsPerPage =
        availableHeight < 600
          ? 6
          : availableHeight < 640
          ? 7
          : availableHeight > 640 && availableHeight < 900
          ? 8
          : availableHeight > 900 && 10;
      setItemsPerPage(newItemsPerPage);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    setPage(0);
  }, [location.pathname, location.state?.data]);

  const handleItemClick = async (item) => {
    const salesDataApi = `${process.env.REACT_APP_SALES_DATA_API}/${item.path}`;
    const access_token = sessionStorage.getItem("access_token");
    const response = await fetch(salesDataApi, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
    if (!response.ok) {
      console.error("Failed to fetch data:", response.statusText);
      return;
    }

    const data = await response.json();
    navigate(`/item/${item.path}`, {
      state: {
        question: item.label,
        data: data,
        categories: categories,
      },
    });
  };

  const toTitleCase = (str) => {
    if (typeof str !== "string") {
      return str; // Return an empty string for non-string inputs
    }
    return str
      .toLowerCase() // Ensure the string is in lowercase first
      .split(/[\s_]+/) // Split the string into an array of words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(" "); // Join the words back into a single string
  };
  return (
    <Container
      sx={{
        flexGrow: 1,
        width: "100%",
        display: "flex",
        gap: "2%",
        margin: "0",
        height: isMediumDown && isLandscape ? "none" : "100vh",
        justifyContent: "flex-end",
        padding: isMediumDown && isLandscape ? "0%" : "4%",
        overflowY: isMediumDown && isLandscape ? "auto" : "",
      }}
      style={{ padding: isMediumDown && isLandscape ? "0px" : "" }}
    >
      {isMediumDown ? (
        <div style={{ width: "100%", height: "100%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              position: isLandscape ? "" : "sticky",
              top: 0,
              height: "170px",
              gap: "8px",
            }}
          >
            <Header isMediumDown={isMediumDown} page={"table"} />

            <CategorySidebar
              categories={categories}
              handleItemClick={handleItemClick}
            />
          </div>
          <div
            style={{
              overflow: "hidden",
              marginLeft: "0%",
              marginTop: isLandscape ? "0%" : "3%",
              padding: isLandscape ? "0px 20px 60px" : "",
            }}
          >
            <div
              style={{
                color: "black",
                fontSize: "16px",
                marginTop: "3%",
                marginLeft: "2%",
                fontWeight: "bold",
                padding: "10px",
                textAlign: "center",
                backgroundColor: "#ffffffc9",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              {question}
            </div>
            <div
              style={{
                overflowX: "auto",
                marginTop: "1%",
                scrollbarWidth: "thin",
                scrollbarColor: "rgb(221, 195, 164) #f4f6f8",
                borderRadius: "4px",
              }}
            >
              <Table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  minWidth: "600px",
                  marginTop: "10px",
                }}
              >
                <TableHead>
                  <TableRow>
                    {displayedData.length > 0 &&
                      Object.keys(displayedData[0]).map((key, index) => (
                        <TableCell
                          key={key}
                          style={{
                            backgroundColor: "rgb(221, 195, 164)",
                            color: "black",
                            fontSize: "16px",
                            fontWeight: "bold",
                            padding: "10px 8px",
                            textAlign: "left",
                            borderBottom: "2px solid #ffffff",
                            position: index === 0 ? "sticky" : "static",
                            top: 0,
                            left: index === 0 ? 0 : "auto",
                            zIndex: index === 0 ? 1 : "auto",
                            whiteSpace: "normal",
                            minWidth: index === 0 ? "150px" : "100px",
                            maxWidth: "500px",
                          }}
                        >
                          {toTitleCase(key)}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayedData.length > 0 ? (
                    displayedData.map((row, rowIndex) => (
                      <TableRow
                        key={rowIndex}
                        style={{
                          backgroundColor:
                            rowIndex % 2 === 0 ? "inherit" : "white",
                          transition: "background-color 0.2s",
                          cursor: "pointer",
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.backgroundColor = "#eaf7ff")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.backgroundColor =
                            rowIndex % 2 === 0
                              ? "rgb(228 203 173 / 47%)"
                              : "white")
                        }
                      >
                        {Object.values(row).map((value, colIndex) => (
                          <TableCell
                            key={colIndex}
                            style={{
                              color: "#333",
                              fontSize: "14px",
                              padding: "10px 8px",
                              textAlign: "left",
                              borderBottom: "1px solid #ffffff",
                              position: colIndex === 0 ? "sticky" : "static",
                              left: colIndex === 0 ? 0 : "auto",
                              zIndex: colIndex === 0 ? 1 : "auto",
                              backgroundColor:
                                colIndex === 0 ? "rgb(221, 195, 164)" : "",
                              whiteSpace: colIndex === 0 ? "normal" : "nowrap",
                              minWidth: colIndex === 0 ? "180px" : "auto",
                              maxWidth: colIndex === 0 ? "300px" : "600px",
                              wordWrap: "break-word",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {toTitleCase(value)}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={Object.keys(displayedData[0] || {}).length}
                        style={{
                          textAlign: "center",
                          padding: "16px",
                          fontSize: "14px",
                          color: "#999",
                          fontStyle: "italic",
                        }}
                      >
                        No data available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
                padding: "10px 20px",
                backgroundColor: "#ffffffc9",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <button
                onClick={() => setPage((prev) => Math.max(prev - 1, 0))}
                disabled={page === 0}
                style={{
                  padding: "8px 16px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: page === 0 ? "#aaa" : "black",
                  backgroundColor: page === 0 ? "#f2f2f2" : "#ffffffc9",
                  border: "1px solid rgb(239 224 207)",
                  borderRadius: "4px",
                  cursor: page === 0 ? "not-allowed" : "pointer",
                  transition: "all 0.3s",
                }}
              >
                Previous
              </button>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#333",
                }}
              >
                Page {page + 1} of {Math.ceil(data.length / itemsPerPage)}
              </span>
              <button
                onClick={() =>
                  setPage((prev) =>
                    Math.min(
                      prev + 1,
                      Math.floor(data.length / itemsPerPage) -
                        (data.length % itemsPerPage === 0 ? 1 : 0)
                    )
                  )
                }
                disabled={
                  (page + 1) * itemsPerPage >= data.length &&
                  data.length % itemsPerPage === 0
                }
                style={{
                  padding: "8px 16px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  color:
                    (page + 1) * itemsPerPage >= data.length &&
                    data.length % itemsPerPage === 0
                      ? "#aaa"
                      : "black",
                  backgroundColor:
                    (page + 1) * itemsPerPage >= data.length &&
                    data.length % itemsPerPage === 0
                      ? "#f2f2f2"
                      : "#ffffffc9",
                  border: "1px solid rgb(239 224 207)",
                  borderRadius: "4px",
                  cursor:
                    (page + 1) * itemsPerPage >= data.length &&
                    data.length % itemsPerPage === 0
                      ? "not-allowed"
                      : "pointer",
                  transition: "all 0.3s",
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div style={{ width: "20%" }}>
            <CategorySidebar
              categories={categories}
              handleItemClick={handleItemClick}
            />
          </div>
          <div style={{ width: "80%", marginTop: "4%" }}>
            <div
              style={{
                color: "black",
                fontSize: "22px", // Slightly larger font size for desktop
                fontWeight: "bold",
                marginBottom: "1%",
                marginTop: "2%",
                paddingBottom: "5px",
                borderBottom: "2px solid #A8A8A8", // Adds a visual separator below the title
              }}
            >
              {question}
            </div>

            <Table
              style={{
                marginTop: "2%",
                borderCollapse: "collapse",
                width: "100%",
              }}
            >
              <TableHead>
                <TableRow>
                  {displayedData.length > 0 &&
                    Object.keys(displayedData[0]).map((key) => (
                      <TableCell
                        key={key}
                        style={{
                          color: "black",
                          fontSize: "16px",
                          fontWeight: "bold",
                          padding: "1.2%",
                          backgroundColor: "white", // Darker shade for headers
                          textAlign: "center", // Center align the header content
                          border: "1px solid #ddd", // Adds border between cells
                        }}
                      >
                        {toTitleCase(key)}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedData.length > 0 ? (
                  displayedData.map((row, index) => (
                    <TableRow key={index}>
                      {Object.values(row).map((value, idx) => (
                        <TableCell
                          key={idx}
                          style={{
                            color: "black",
                            fontSize: "14px",
                            padding: "12px 8px",
                            textAlign: "center", // Center align the row content
                            borderBottom: "1px solid #ddd",
                            backgroundColor:
                              index % 2 === 0 ? "rgb(232 223 212)" : "#FFF",
                            wordWrap: "break-word", // Handles long text without breaking the layout
                          }}
                        >
                          {toTitleCase(value)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={Object.keys(displayedData[0] || {}).length}
                      style={{
                        color: "black",
                        textAlign: "center",
                        padding: "20px",
                        fontSize: "14px",
                      }}
                    >
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
                padding: "10px 20px",
                backgroundColor: "#f9f9f9",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <button
                onClick={() => setPage((prev) => Math.max(prev - 1, 0))}
                disabled={page === 0}
                style={{
                  padding: "8px 16px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: page === 0 ? "#aaa" : "black",
                  backgroundColor:
                    page === 0 ? "rgb(232 223 212)" : "rgb(232 223 212)",
                  border: "1px solid #A8A8A8",
                  borderRadius: "4px",
                  cursor: page === 0 ? "not-allowed" : "pointer",
                  transition: "all 0.3s ease-in-out",
                }}
              >
                Previous
              </button>

              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#333",
                  textAlign: "center",
                }}
              >
                Page {page + 1} of {Math.ceil(data.length / itemsPerPage)}
              </span>

              <button
                onClick={() =>
                  setPage((prev) =>
                    Math.min(
                      prev + 1,
                      Math.floor(data.length / itemsPerPage) - 1
                    )
                  )
                }
                disabled={page >= Math.ceil(data.length / itemsPerPage) - 1}
                style={{
                  padding: "8px 16px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  color:
                    page >= Math.ceil(data.length / itemsPerPage) - 1
                      ? "#aaa"
                      : "black",
                  backgroundColor:
                    page >= Math.ceil(data.length / itemsPerPage) - 1
                      ? "white"
                      : "rgb(232 223 212)",
                  border: "1px solid #A8A8A8",
                  borderRadius: "4px",
                  cursor:
                    page >= Math.ceil(data.length / itemsPerPage) - 1
                      ? "not-allowed"
                      : "pointer",
                  transition: "all 0.3s ease-in-out",
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default ItemPage;
